<template>
    <div class="content">
        <div class="cloud-header">
            <h2>default</h2>
            积分商城test
        </div>
        <div class="cloud-body">
        </div>
    </div>
</template>
<script type="text/javascript">
// import empty from './empty.vue'
export default {
    components: {
        // empty
    },
    data() {
        return {

        };
    },
    /**
     * 计算属性
     * @type {Object}
     */
    computed: {

    },
    /**
     * 页面渲染前
     * @return {[type]} [description]
     */
    created() {

    },
    /**
     * 数据监听
     * @type {Object}
     */
    watch: {

    },
    /**
     * 页面加载执行
     * @return {[type]} [description]
     */
    async mounted() {

    },
    methods: {

    }
};
</script>
<style lang="less" scoped="true">
</style>